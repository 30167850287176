<template>
  <div class="RefundInformation">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn2">
          <span style="height: 40px; line-height: 40px">赛事名称：</span>
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="raceName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">审核状态：</span>
          <el-select
            v-model="statusRefund"
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="审核中" value="0"></el-option>
            <el-option label="审核成功" value="1"></el-option>
            <el-option label="审核失败" value="2"></el-option>
          </el-select>
          <el-button
            style="height: 40px; margin-left: 10px"
            type="primary"
            plain
            @click="searchUnitAwardList"
            >查询</el-button
          >
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="600"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="赛事名称" width="500">
            <template slot-scope="scope">
              <span>{{ scope.row.competitionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位名称" width="250">
            <template slot-scope="scope">
              <span>{{ scope.row.unitName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="负责人" width="250">
            <template slot-scope="scope">
              <span>{{ scope.row.contactName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="负责人电话" width="270">
            <template slot-scope="scope">
              <span>{{ scope.row.contactPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                      size="mini"
                      @click="handleRecordList(scope.$index, scope.row)"
              >奖品记录</el-button>
              <el-button
                      size="mini"
                      @click="handleLogisticsList(scope.$index, scope.row)"
              >邮寄奖品</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formModel: {},
      applyReason: "",
      remarks: "",
      result: "",
      RefundId: "",
      statusRefund: "",
      refundType: "",
      raceName: "",
      playerName: "",
      startTime: "",
      endTime: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      dialogVisible: false,
    };
  },
  methods: {
    dialogClose() {},
    handleSizeChange(val) {
      this.pageSize = val;
      this.findUnitAwardList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.findUnitAwardList();
    },
    //获取所有信息
    async findUnitAwardList() {
      try {
        const result = await this.$api.unitAwardList(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item && item.playerNames) {
            item.playerNames = JSON.parse(item.playerNames).join(",");
          }
        });
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //查询信息
    async searchUnitAwardList() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.unitAwardList(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_EQ_competitionName=${this.raceName}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item && item.playerNames) {
            item.playerNames = JSON.parse(item.playerNames).join(",");
          }
        });
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //奖品记录列表
    handleRecordList(index, row) {
      this.$router.push({
        name: "AwardRecordList",
        params: {
          back: row.name,
          id: row.id,
          competitionId: row.competitionId,
          unitId: row.unitId,
          pageSize: 10,
          currentPage: 1,
        },
      });
    },
    //物流列表
    handleLogisticsList(index, row) {
      this.$router.push({
        name: "AwardLogisticsList",
        params: {
          back: row.name,
          id: row.id,
          pageSize: 10,
          currentPage: 1,
        },
      });
    },
  },
  created() {
    this.findUnitAwardList();
  },
};
</script>

<style lang="less">
.RefundInformation {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
      .btn2 {
        display: flex;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 150px;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
